<mat-card>
  <div class="content-center">
    <mat-card-header>
      <mat-card-title>
        <mat-icon>{{ featureName.iconName }}</mat-icon>
        <span role="heading" aria-level="3">
          {{ featureName.title | titlecase }}
        </span>
      </mat-card-title>
    </mat-card-header>
  </div>

  <mat-card-content>
    @if (loading) {
    <div>
      <ul>
        <ngx-skeleton-loader [theme]="{ width: '80%' }" />
        <ngx-skeleton-loader [theme]="{ width: '90%' }" />
        <ngx-skeleton-loader [theme]="{ width: '40%' }" />
        <ngx-skeleton-loader [theme]="{ width: '70%' }" />
      </ul>
    </div>
    } @else {
    <ul>
      @for (service of services; track service) {
      <li>
        <a [routerLink]="['/services']" [queryParams]="{ search: service.name }">{{ service.name }}</a>
      </li>
      }
    </ul>
    }
  </mat-card-content>

  @if (!loading) {
  <mat-card-actions class="content-center">
    <button
      [attr.data-testid]="'view-all-' + featureName.title"
      mat-button
      [routerLink]="['/services']"
      [queryParams]="{ category: featureName.title }"
    >
      View all
    </button>
  </mat-card-actions>
  }
</mat-card>
