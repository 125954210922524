<h1 mat-dialog-title>Warning</h1>
<mat-dialog-content style="padding-top: 0.5rem">
  <p>Are you sure you want to delete? This action cannot be undone.</p>
</mat-dialog-content>
<mat-dialog-actions class="flex-row">
  @if (loading ? true : false) {
  <mat-progress-bar mode="indeterminate" aria-label="loading" />
  }
  <button mat-button (click)="onNoClick()" [disabled]="loading" cdkFocusInitial data-testid="cancel">Cancel</button>
  <button mat-button (click)="onDeleteClick()" [disabled]="loading" color="warn" data-testid="delete-confirm">
    Delete
  </button>
</mat-dialog-actions>
