@if ({ loading: loading$ | async, error: error$ | async, statusData: statusData$ | async }; as vm) { @if (!vm.error) {
<div>
  @if (vm.loading) {
  <div>
    <div style="width: 50%">
      <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
    </div>
    <div style="width: 40%">
      <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
    </div>
  </div>
  } @else {
  <div class="zone-status">
    <div>Zone 1:</div>
    <a
      target="_blank"
      rel="noopener"
      href="https://www.cityofkingston.ca/emergency-services-and-public-health/open-air-fire-permits/#fire-ban-status"
      [ngClass]="getStatusChipClass(vm.statusData?.zone1Status)"
      class="custom-chip"
    >
      {{ vm.statusData?.zone1Status }}
    </a>
  </div>
  <div class="zone-status">
    <div>Zone 2:</div>
    <a
      target="_blank"
      rel="noopener"
      href="https://www.cityofkingston.ca/emergency-services-and-public-health/open-air-fire-permits/#fire-ban-status"
      [ngClass]="getStatusChipClass(vm.statusData?.zone2Status)"
      class="custom-chip"
    >
      {{ vm.statusData?.zone1Status }}
    </a>
  </div>
  }
</div>
} @if (vm.error) {
<div data-test="error-message" class="error-message">
  <app-message-display [showCloseButton]="false"> Unable to retrieve current zone status. </app-message-display>
</div>
} }
