@if (currentWindow !== currentWindow.parent && production) {
<div>
  <p>MyKingston cannot be loaded in an iframe</p>
</div>
} @else {
<div class="app" [ngClass]="isDarkMode ? 'theme-dark' : ''">
  <!-- <app-announcement-banner /> -->
  <header>
    @if (loggedIn$ | async) {
    <app-activity-detection />
    }
    <app-header (darkModeSwitched)="switchMode($event)" (menuToggle)="onMenuToggle()" [mode]="sideNavMode" />
  </header>
  <main>
    <app-content-shell
      [mode]="sideNavMode"
      [sideNavToggle]="sideNavOpen"
      [hideSideNav]="hideSideNavInitial"
      [loggedIn]="loggedIn$ | async"
      (sideNavClosed)="onSideNavClosed()"
      ><router-outlet />
    </app-content-shell>
  </main>
  <footer><app-footer /></footer>
</div>
}
