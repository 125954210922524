<div class="tab-desc mat-subtitle-1">
  {{ instructions_label }}
</div>
<div class="verification-area">
  <h2>Email verification</h2>
  <form (ngSubmit)="onVerify()" id="verifyForm" #verificationForm="ngForm">
    <mat-form-field color="accent" appearance="outline">
      <mat-label>6-digit code</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="verificationCode"
        name="verificationCodeUser"
        required
        data-testid="code"
        maxlength="6"
        minlength="6"
      />
      <mat-error align="end"> Enter the verification code</mat-error>
    </mat-form-field>
    <p>
      @if (error) {
      <mat-error>{{ error }}</mat-error>
      }
    </p>
    <div class="break"></div>
    <button
      mat-flat-button
      type="submit"
      color="accent"
      form="verifyForm"
      class
      data-testid="verify"
      [disabled]="!(verificationForm.valid && verificationForm.dirty)"
    >
      Verify
    </button>
  </form>
  <div class="new-code-area">
    <p>If you lost the code or it has expired</p>
    <button mat-flat-button color="primary" type="button" (click)="onResend()" data-testid="new-verification-code">
      Send new verification code
    </button>
  </div>
  @if (loading) {
  <mat-progress-bar mode="indeterminate" aria-label="loading" />
  }
</div>
