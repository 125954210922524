@if ({ widgetSettings: widgetSettings$ | async }; as vm) { @if (vm.widgetSettings.visible) {
<div class="widget" data-testid="district-widget">
  <h2>District details</h2>
  @if (!addressesLoaded && loggedIn && !addressError) {
  <div class="loading">
    <div style="width: 50%">
      <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
    </div>
    <div style="width: 40%">
      <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
    </div>
    <div style="width: 50%">
      <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
    </div>
  </div>
  } @else {
  <app-district-details
    [loading]="!addressesLoaded"
    [districtData]="{
      electoralDistrictName: address?.electoralDistrictName,
      electoralDistrictId: address?.electoralDistrictId,
      councillorFirstName: address?.councillorFirstName,
      councillorLastName: address?.councillorLastName,
      councillorWebsiteUrl: address?.councillorWebsiteUrl
    }"
  />
  @if (!address && addressesLoaded && !showDescription) {
  <div>
    <p>Add a Kingston address to view your district details.</p>
    <app-add-residential-address />
  </div>
  } } @if (showDescription && (!loggedIn || !address || (address && !address?.electoralDistrictName))) {
  <div>Keep up to date on your neighbourhood's electoral district and councillor details.</div>
  } @if (address && !address?.electoralDistrictName && !showDescription) {
  <div>
    <app-message-display messageType="info" [showCloseButton]="false">
      @if (address.isKingston) {
      <span> No district details found for {{ address?.streetAddress }}. </span>
      } @else { A Kingston address is required for district details }
    </app-message-display>
    <button
      mat-raised-button
      color="accent"
      data-testid="edit-address"
      (click)="onEdit(address)"
      style="margin: 0.5rem 0 0.5rem 0"
    >
      Edit address
    </button>
  </div>
  }
</div>
} }
