@if (!hasStreetNumber) {
<div class="warning-message">
  <app-message-display [showCloseButton]="false" messageType="info">
    Address must have a street number.
  </app-message-display>
</div>
}

<mat-form-field color="accent" class="full-width" appearance="outline" data-testid="streetAddressAuto">
  <mat-label>Search for your address *</mat-label>
  <input
    data-testid="Address-search"
    matInput
    autocomplete="off"
    [formControl]="addressControl"
    maxlength="100"
    aria-label="Address search"
    type="text"
    placeholder="ex. 216 Ontario St"
    name="address"
    #searchField
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)"
    class="custom-autocomplete-panel"
  >
    @for (option of filteredOptions | async; track option; let last = $last) {
    <mat-option [value]="option" class="custom-option">
      <div class="option-content">
        <mat-icon class="option-icon">location_pin</mat-icon>
        <span class="option-text">{{ option.description }}</span>
      </div>
      @if (!last) {
      <mat-divider />
      }
    </mat-option>
    }
  </mat-autocomplete>
  <mat-error align="end">Search for an address</mat-error>
  <span matSuffix><mat-icon>search</mat-icon></span>
</mat-form-field>

<div #map style="display: none"></div>
