<h2 mat-dialog-title>Open air fire permit</h2>

<div mat-dialog-content>
  <div class="table">
    @for (permit of data.permits | async; track permit) {
    <div>
      <div class="tr">
        <div class="td label first">Type:</div>
        <div class="td">{{ permit.subject }}</div>
      </div>
      @if (permit.fire_zone_id) {
      <div class="tr">
        <div class="td label">Zone:</div>
        <div class="td">{{ permit.fire_zone_id }}</div>
      </div>
      }
      <div class="tr">
        <div class="td label">Expiry date:</div>
        <div class="td last">{{ permit.date_expires | date }}</div>
      </div>
      <div class="tr">
        <div class="td label">Address:</div>
        <div class="td">{{ permit.geo_street_address }}</div>
      </div>
      <mat-divider />
    </div>
    }
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial data-testid="close">Close</button>
</div>
