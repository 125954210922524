import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentCustomer } from '../../../../app/customer/store/selectors/customer.selector';
import { Customer } from '../../../customer/customer.model';
import * as fromApp from '../../../store/app.reducer';
import { ServiceCard } from '../../data-access/service-card.model';
import {
  MatCard,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
  MatCardContent,
  MatCardActions,
} from '@angular/material/card';
import { AsyncPipe } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCardContent,
    MatCardActions,
    MatButton,
    MatIconButton,
    MatIcon,
    AsyncPipe,
  ],
})
export class ServiceCardComponent implements OnInit {
  private store = inject<Store<fromApp.AppState>>(Store);

  @Input() serviceCard: ServiceCard;
  @Input() loggedIn = false;
  @Output() toggleFavorite: EventEmitter<string> = new EventEmitter();

  customer$: Observable<Customer>;
  linkHref: string;
  ngOnInit() {
    this.customer$ = this.store.pipe(select(selectCurrentCustomer));
    this.linkHref =
      this.serviceCard.initiateUrlSSO && this.loggedIn ? this.serviceCard.initiateUrlSSO : this.serviceCard.initiateUrl;

    if (this.serviceCard.signInRequired) {
      this.linkHref = this.serviceCard.initiateUrlSSO;
    }
  }

  onFavorite(serviceId: string) {
    this.toggleFavorite.emit(serviceId);
  }
}
