<mat-toolbar color="primary" class="toolbar">
  @if (mode === 'over') {
  <span>
    <button mat-icon-button aria-label="menu" (click)="onToggleMenu()" id="menu-button" data-testid="menu">
      <mat-icon>menu</mat-icon>
    </button>
  </span>
  } @if (mode === 'side') {
  <img class="logo" src="../../../assets/images/svg/cityofkingston_logo_header.svg" alt="City of Kingston logo" />
  }
  <span routerLink="/" style="cursor: pointer" data-testid="app-title" class="toolbar-text">MyKingston</span>

  <span class="header-spacer"></span>

  @if (customerName$ | async; as user) {
  <span data-testid="username" class="toolbar-text">
    {{ user.given_name }}
  </span>
  }
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu" data-testid="account" id="profile">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    @if (isLoggedIn$ | async) {
    <button mat-menu-item [routerLink]="['/profile', 0]" data-testid="account-profile">
      <mat-icon>manage_accounts</mat-icon>
      <span>My Account</span>
    </button>
    }

    <button mat-menu-item data-testid="dark-mode" (click)="onToggleDarkMode(darkMode)">
      <!-- <mat-icon>dark_mode</mat-icon> -->
      <mat-slide-toggle
        #darkMode
        class="toggle"
        color="accent"
        (click)="$event.stopPropagation()"
        (change)="onDarkModeSwitched($event)"
        [checked]="(customerPreferences$ | async).darkMode"
      >
        Dark mode
      </mat-slide-toggle>
    </button>

    @if (isLoggedIn$ | async) {
    <button mat-menu-item (click)="onLogout()" data-testid="logout">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
    } @else {
    <button mat-menu-item (click)="onLogin()" data-testid="login">
      <mat-icon>login</mat-icon>
      <span>Login / Sign-up</span>
    </button>
    }
  </mat-menu>
</mat-toolbar>
@if (authChecking$ | async) {
<mat-progress-bar mode="indeterminate" color="accent" />
}
