<div class="container mat-elevation-z2">
  <div>
    <img [src]="imageSource" alt="City of Kingston logo" width="130" height="130" />
  </div>

  <div class="content">
    <h1 class="mat-h1">Welcome to MyKingston!</h1>
    <h2 class="mat-h3">Your portal for accessing City services and information.</h2>

    <app-create-register-account />
  </div>
</div>
