@if ({ loading: loading$ | async, requests: firePermitRequests$ | async, error: error$ | async, widgetSettings:
widgetSettings$ | async }; as vm) { @if (vm.widgetSettings.visible) {
<div class="widget" data-testid="fire-permit-widget">
  <h2>Open air fire permits</h2>

  <app-fire-ban-status />
  <div class="recentFirePermit">Most recent permit</div>
  @if (vm.error) {
  <div data-test="error-message" class="error-message">
    <app-message-display errorMessage="Service request error." [showCloseButton]="false">
      Something went wrong and we are unable to show you your permits. The issue has been reported and we are
      investigating.
    </app-message-display>
  </div>
  } @if (!vm.error) {
  <div>
    @if (vm.loading) {
    <div>
      <div style="width: 50%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
      </div>
      <div style="width: 40%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
      </div>
      <div style="width: 50%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
      </div>
      <div style="width: 60%">
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
      </div>
    </div>
    } @else { @if (vm.requests?.length) {
    <div>
      <div class="table">
        <div class="tr">
          <div class="td label">Type:</div>
          <div class="td last">{{ vm.requests[0].subject }}</div>
        </div>
        @if (vm.requests[0]?.fire_zone_id) {
        <div class="tr">
          <div class="td label first">Zone:</div>
          <div class="td">{{ vm.requests[0].fire_zone_id }}</div>
        </div>
        }
        <div class="tr">
          <div class="td label">Expires:</div>
          <div class="td">{{ vm.requests[0].date_expires | date }}</div>
        </div>
        <div class="tr">
          <div class="td label">Address:</div>
          <div class="td">{{ vm.requests[0].short_address }}</div>
        </div>
      </div>
    </div>
    } @else {
    <p>No fire permits found.</p>
    } } @if (loggedIn && !vm.loading) {
    <div class="action-buttons">
      @if (vm.requests?.length) {
      <button mat-stroked-button (click)="onAllPermits()" data-testid="view-all-fire-permit">View all permits</button>
      } @else {
      <a
        mat-stroked-button
        href="https://www.cityofkingston.ca/emergency-services-and-public-health/open-air-fire-permits/"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="permit-details-link"
      >
        Permit details
      </a>
      }
      <div data-test="create-request">
        <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank">Apply for permit</a>
      </div>
    </div>
    }
  </div>
  }
</div>
} }
