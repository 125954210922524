import { ChangeDetectionStrategy, Component, OnInit, Input, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { FirePermit } from './data-access/fire-permit.model';
import { FirePermitRequestStore } from './data-access/fire-permit.store';
import { select, Store } from '@ngrx/store';
import { selectAppSettings } from '../../app-settings/store/app-settings.selectors';
import { BaseWidgetSettings } from '../../app-settings/app-settings.model';
import { SharedModule } from '../../shared/shared.module';
import { FirePermitListComponent } from './ui/fire-permit-list/fire-permit-list.component';
import { FireBanStatusComponent } from './ui/fire-ban-status/fire-ban-status.component';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-fire-permit',
  templateUrl: './fire-permit.component.html',
  styleUrls: ['./fire-permit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, FireBanStatusComponent, MatButtonModule, MatIcon],
})
export class FirePermitComponent implements OnInit {
  dialog = inject(MatDialog);
  private requestsStore = inject(FirePermitRequestStore);
  private store = inject(Store);

  crmPortalLink = `${env.osvcSSOlink}/${env.osvcCategoryMap.firePermit}`;

  firePermitRequests$: Observable<FirePermit[]>;
  loading$: Observable<boolean>;
  error$: Observable<string>;
  widgetSettings$: Observable<BaseWidgetSettings>;
  @Input() loggedIn = true;

  ngOnInit(): void {
    this.requestsStore.loadFirePermits();

    this.firePermitRequests$ = this.requestsStore.permits$;
    this.error$ = this.requestsStore.permitError$;
    this.loading$ = this.requestsStore.permitLoading$;

    this.widgetSettings$ = this.store.pipe(
      select(selectAppSettings),
      map((settings) => settings.widgets.fire_permit)
    );
  }

  onAllPermits() {
    this.dialog.open(FirePermitListComponent, {
      data: {
        permits: this.firePermitRequests$,
      },
    });
  }
}
