@if (loading) {
<div>
  <div style="width: 50%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
  </div>
  <div style="width: 40%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
  </div>
  <div style="width: 50%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
  </div>
  <div style="width: 60%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
  </div>
</div>
} @else { @if (requests?.length) {
<div data-testid="service-requests">
  <div id="table">
    @for (request of requestToDisplay; track $index) {
    <div>
      <div class="tr">
        <div class="td label first">Ref #</div>
        <div class="td">{{ request.ref_no }}</div>
      </div>
      <div class="tr">
        <div class="td label">Status</div>
        <div class="td">{{ request.status }}</div>
      </div>
      <div class="tr">
        <div class="td label">Created</div>
        <div class="td">{{ request?.created | date }}</div>
      </div>
      <div class="tr">
        <div class="td label">Subject</div>
        <div class="td last">{{ request.subject }}</div>
      </div>
      @if (limit > 1) {
      <mat-divider />
      }
    </div>
    }
  </div>
</div>
} @else {
<div data-test="no-requests">
  <p>You do not have any service requests.</p>
</div>
} }
