<mat-sidenav-container class="nav-container">
  @if (!hideSideNav) {
  <mat-sidenav #sidenav [mode]="mode" class="mat-elevation-z3" (closedStart)="navCloseStart()">
    <div class="nav-content">
      <mat-nav-list>
        <a
          cdkFocusInitial
          mat-list-item
          routerLink="/home"
          routerLinkActive="is-active"
          (click)="closeSideNav()"
          id="dashboard"
          data-testid="nav-home"
        >
          <div class="menu-item">
            <mat-icon matListIcon>dashboard</mat-icon>
            <span> Home </span>
          </div>
        </a>
        <div style="padding-top: 1em; padding-bottom: 1em">
          <mat-divider />
        </div>
        @if (loggedIn) {
        <span>
          <a
            mat-list-item
            [routerLink]="['/profile', 1]"
            routerLinkActive="is-active"
            (click)="closeSideNav()"
            id="my-profile"
            data-testid="nav-profile"
          >
            <div class="menu-item">
              <mat-icon>account_circle</mat-icon>
              <span> My profile </span>
            </div>
          </a>
        </span>
        }
        <a
          mat-list-item
          routerLink="/address"
          (click)="closeSideNav()"
          routerLinkActive="is-active"
          data-testid="nav-residence"
          id="nav-residence"
        >
          <div class="menu-item"><mat-icon>home</mat-icon> Residence</div></a
        >
        <a
          mat-list-item
          routerLink="/services"
          routerLinkActive="is-active"
          (click)="closeSideNav()"
          id="services"
          data-testid="nav-services"
          ><div class="menu-item"><mat-icon>search</mat-icon><span> Services </span></div></a
        >
      </mat-nav-list>
      <mat-action-list class="bottom-half-nav">
        <div style="padding-top: 1em; padding-bottom: 1em">
          <mat-divider />
        </div>
        @if (loggedIn) {
        <a
          mat-list-item
          routerLink="/subscriptions"
          routerLinkActive="is-active"
          (click)="closeSideNav()"
          data-testid="nav-subscription"
        >
          <div class="menu-item">
            <mat-icon>notifications_none</mat-icon>
            <span> Email notifications </span>
          </div>
        </a>
        }
        <button class="feedback" data-testid="give-feedback" mat-list-item (click)="onFeedback()">
          <div class="menu-item subtitle">
            <mat-icon>chat_bubble_outlined</mat-icon><span style="margin-bottom: 7px"> Give feedback </span>
          </div>
        </button>
      </mat-action-list>
    </div>
  </mat-sidenav>
  }

  <mat-sidenav-content>
    <div class="container" [style.margin-left]="sidenav?.opened ? '0px' : '0px'">
      @if ((checkingAuth$ | async) === false) {
      <ng-content />
      }
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
